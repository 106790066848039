import React from "react";
import { IdentifierInputProps } from "./IdentifierInput.types";
import { inputIdForIdentifier, labelForIdentifier } from "auth-ui-client";
import { kigFieldNames } from "@/localizations/KigFieldNames";
import { AccountSelectField } from "../AccountSelectField";
import { AssignmentSelectField } from "../AssignmentSelectField";
import { FormItem } from "../FormItem";

export const AccountSelectFieldAdapter: React.FC<IdentifierInputProps> = ({
  identifierType,
  storage,
  setIdentifier,
  fieldNames,
  identifier,
  assignments,
  showLabel,
  showPlaceholder,
}) => (
  <>
    <FormItem
      label={showLabel ? labelForIdentifier(identifier, kigFieldNames) : undefined}
      htmlFor={inputIdForIdentifier(identifier)}
    >
      <AccountSelectField
        id={identifierType}
        value={identifier.account}
        setter={(val) =>
          setIdentifier((prevState) => {
            return {
              ...prevState,
              account: val,
            };
          })
        }
        storageId={storage?.id || null}
        placeholder={showPlaceholder ? kigFieldNames.ACCOUNT_PLACEHOLDER : undefined}
      />
    </FormItem>

    {assignments.length > 0 && (
      <FormItem label={fieldNames.ASSIGNMENT_LABEL} htmlFor="assignment-select">
        <AssignmentSelectField
          key={identifier.assignment?.id}
          value={identifier.assignment}
          setter={(val) => {
            setIdentifier((prevState) => {
              return {
                ...prevState,
                assignment: val,
              };
            });
          }}
          values={assignments}
          placeholder={showPlaceholder ? fieldNames.ASSIGNMENT_PLACEHOLDER : undefined}
        />
      </FormItem>
    )}
  </>
);
