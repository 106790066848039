import React from "react";
import { IdentifierInputProps } from "./IdentifierInput.types";
import { kigFieldNames } from "@/localizations/KigFieldNames";
import { Input } from "../Input";
import { EMAIL_MAX_LENGTH, EMAIL_TEST_ID } from "./IdentifierInput.const";
import Image from "next/image";
import email from "./email.svg";
import { FormItem } from "../FormItem";

export const TextInputEmailAdapter: React.FC<IdentifierInputProps> = ({
  identifierType,
  setIdentifier,
  fieldNames,
  identifier,
  showLabel,
  emailValidation,
  className,
}) => {
  const trimInitialValue = (value: string) => {
    setIdentifier((prevState) => {
      return {
        ...prevState,
        email: value?.trim(),
        type: identifierType,
      };
    });
  };

  const normalizeAndSaveInput = (value: string) => {
    setIdentifier((prevState) => {
      return {
        ...prevState,
        email: value,
        type: identifierType,
      };
    });
  };

  return (
    <FormItem
      validateStatus={emailValidation?.validateStatus}
      help={emailValidation?.help}
      label={showLabel ? kigFieldNames.EMAIL_LABEL : undefined}
      htmlFor={identifierType}
    >
      <Input
        prefix={<Image src={email} alt="" />}
        id={identifierType}
        className={className}
        value={identifier.email || ""}
        name={identifierType}
        placeholder={fieldNames.EMAIL_PLACEHOLDER}
        maxLength={EMAIL_MAX_LENGTH}
        testId={EMAIL_TEST_ID}
        setter={trimInitialValue}
        onChange={(event) => {
          normalizeAndSaveInput(event.target.value);
        }}
        onBlur={(event) => {
          trimInitialValue(event.target.value);
        }}
      />
    </FormItem>
  );
};
