import React from "react";
import { Assignment } from "auth-ui-client-api";
import { createStaticSelectAction } from "auth-ui-client";
import { AssignmentSelectFieldProps } from "./AssignmentSelectField.types";
import { render } from "./AssignmentSelectField.utils";
import { SelectNext } from "../SelectNext";

export const AssignmentSelectField: React.FC<AssignmentSelectFieldProps> = ({
  id,
  value,
  setter,
  placeholder,
  values,
}) => {
  const onChange = (value?: Assignment) => {
    setter(value ?? null);
  };

  return (
    <SelectNext<Assignment>
      id={id}
      key={JSON.stringify(value)}
      onChange={onChange}
      getItems={createStaticSelectAction(values, "id")}
      render={render}
      getId="id"
      placeholder={placeholder}
      value={value ?? undefined}
      showSearch={false}
      allowClear
    />
  );
};
