import { IdentifierType } from "auth-ui-client-api";
import { IdentifierInputProps } from "./IdentifierInput.types";
import { MobileInputAdapter } from "./MobileInputAdapter";

import { AccountSelectFieldAdapter } from "./AccountSelectFieldAdapter";
import { TextInputEmailAdapter } from "./TextInputEmailAdapter";
import { TextInputLoginAdapter } from "./TextInputLoginAdapter";

export const INPUT_DICTIONARY: { [key in IdentifierType]: React.FC<IdentifierInputProps> } = {
  // TODO: <- нет макетов
  MOBILE: MobileInputAdapter,
  SELECTED_ACCOUNT: AccountSelectFieldAdapter,
  // ->
  LOGIN: TextInputLoginAdapter,
  EMAIL: TextInputEmailAdapter,
};

export const EMAIL_MAX_LENGTH = 100;
export const EMAIL_TEST_ID = "EmailInput";

export const LOGIN_MAX_LENGTH = 128;
export const LOGIN_TEST_ID = "LoginInput";
