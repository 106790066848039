import React from "react";
import { IdentifierInputProps } from "./IdentifierInput.types";
import { kigFieldNames } from "@/localizations/KigFieldNames";
import { Input } from "../Input";
import { LOGIN_MAX_LENGTH, LOGIN_TEST_ID } from "./IdentifierInput.const";
import Image from "next/image";
import person from "./person.svg";
import { FormItem } from "../FormItem";

export const TextInputLoginAdapter: React.FC<IdentifierInputProps> = ({
  identifierType,
  setIdentifier,
  fieldNames,
  identifier,
  showLabel,
  className,
}) => {
  const trimInitialValue = (value: string) => {
    setIdentifier((prevState) => {
      return {
        ...prevState,
        login: value?.trim(),
        type: identifierType,
      };
    });
  };

  const normalizeAndSaveInput = (value: string) => {
    setIdentifier((prevState) => {
      return {
        ...prevState,
        login: value,
        type: identifierType,
      };
    });
  };

  return (
    <FormItem label={showLabel ? kigFieldNames.LOGIN_LABEL : undefined} htmlFor={identifierType}>
      <Input
        prefix={<Image src={person} alt="" />}
        id={identifierType}
        className={className}
        value={identifier.login || ""}
        name={identifierType}
        placeholder={fieldNames.LOGIN_PLACEHOLDER}
        maxLength={LOGIN_MAX_LENGTH}
        testId={LOGIN_TEST_ID}
        setter={trimInitialValue}
        onChange={(event) => {
          normalizeAndSaveInput(event.target.value);
        }}
        onBlur={(event) => {
          trimInitialValue(event.target.value);
        }}
      />
    </FormItem>
  );
};
