import { ReactElement } from "react";
import { LabeledValue, SelectProps } from "antd/lib/select";

export type StringProperty<T extends ObjectWithStringValueOfK<K>, K extends Property<T>> = T[K] extends string
  ? K
  : never;
export type Property<T> = keyof T & string;
export type ObjectWithStringValueOfK<K extends string> = Record<string, unknown> &
  Record<K, string> &
  Record<string, Exclude<any, string>>;

export type GetItemsCanceledResponse<Data> = Promise<Data | Canceled | Error>;
export type GetItemsResponse<Data> = Promise<Data | Error>;

export type Canceled = { canceled: true };

export type Error = { error: true };
export const isCanceled = (data: any | Canceled): data is Canceled => {
  return (data as Canceled).canceled !== undefined;
};

export const isError = (data: any | Error): data is Error => {
  return (data as Error).error !== undefined;
};

export type RenderFunction<DomainObject> = (domainObject: DomainObject, searchParam?: string) => ReactElement;

export interface SelectNextProps<DomainObject>
  extends Omit<
    SelectProps<SelectSingleValue<DomainObject>[], SelectSingleValue<DomainObject>>,
    | "defaultValue"
    | "value"
    | "mode"
    | "getPopupContainer"
    | "labelInValue"
    | "filterOption"
    | "dropdownRender"
    | "onDropdownVisibleChange"
    | "onChange"
    | "dropdownClassName"
    | "onSelect"
    | "onDeselect"
    | "filterSort"
    | "renderSelectedForMultiple"
    | "multiple"
  > {
  defaultValue?: DomainObject;
  value?: DomainObject;
  id?: string;
  getItems: GetItems<DomainObject>;
  render: RenderFunction<DomainObject>;
  getId: GetStringProp<DomainObject>;
  onChange?: (value?: DomainObject) => void;
  disabledIds?: string[];
  minInputForSearch?: number;
  maxInputForSearch?: number;
  parentSelector?: string;
  visible?: boolean;
  nowrap?: boolean;
  /* property для расширения возможностей select, предполагается использовать только внутри этого проекта */
  itemsToOptions?: (args: {
    items: DomainObject[];
    render: RenderFunction<DomainObject>;
    getId: GetStringProp<DomainObject>;
    searchCriteria: string;
    disabledIds?: string[];
  }) => SelectSingleValue<DomainObject>[];
  onSelect?: never; // эти свойства мы не используем, но если их не исключить - ломается ts
  onDeselect?: never;
  filterSort?: never;
  optionRender?: any;
}

// noinspection JSUnusedGlobalSymbols
export interface ItemsProvider<DomainObject> {
  getItems: (offset: number, criteria?: string) => GetItemsResponse<DomainObject>;
}

export type GetStringProp<DomainObject> = ((domainObject: DomainObject) => string) | (keyof DomainObject & string);

export interface SelectSingleValue<DomainObject> extends LabeledValue {
  key?: string;
  value: string;
  label: ReactElement<SelectLabelProps<DomainObject>>;
  disabled: boolean;
}

export type SelectLabelProps<T> = {
  domainObject: T;
  searchParam?: string;
  render: RenderFunction<T>;
  className?: string | undefined | null;
};

export type ItemsPage<DomainObject> = { items: DomainObject[]; hasMore: boolean };

export type GetItems<DomainObject> = (
  offset: number,
  criteria?: string
) => GetItemsCanceledResponse<ItemsPage<DomainObject>>;
