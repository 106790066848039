import React from "react";
import { StorageItem } from "auth-ui-client-api";
import { createStaticSelectAction } from "auth-ui-client";
import { StorageSelectFieldProps } from "./StorageSelectField.types";
import { render } from "./StorageSelectField.utils";
import { SelectNext } from "../SelectNext";

export const StorageSelectField: React.FC<StorageSelectFieldProps> = ({ id, value, setter, storages, placeholder }) => {
  const onChange = (value?: StorageItem) => {
    setter(value ?? null);
  };

  return (
    <SelectNext<StorageItem>
      id={id}
      key={JSON.stringify(value)}
      onChange={onChange}
      getItems={createStaticSelectAction(storages, "title")}
      render={render}
      getId="id"
      placeholder={placeholder}
      defaultValue={value ?? undefined}
    />
  );
};
