import React from "react";
import { Account } from "auth-ui-client-api";
import { createSelectAction } from "auth-ui-client";
import { AccountSelectFieldProps } from "./AccountSelectField.types";
import { render } from "./AccountSelectField.utils";
import { SelectNext } from "../SelectNext";

export const AccountSelectField: React.FC<AccountSelectFieldProps> = ({
  id,
  value,
  setter,
  placeholder,
  storageId,
}) => {
  const onChange = (value?: Account) => {
    setter(value ?? null);
  };

  return (
    <SelectNext<Account>
      id={id}
      key={JSON.stringify(value)}
      onChange={onChange}
      getItems={createSelectAction(`./api/public/accounts`, { storageId })}
      render={render}
      getId="id"
      placeholder={placeholder}
      defaultValue={value ?? undefined}
      allowClear
    />
  );
};
