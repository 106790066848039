import React, { ReactElement } from "react";
import { StorageItem } from "auth-ui-client-api";
import { HighlightText } from "auth-ui-client";

export const render = (item: StorageItem, searchParam?: string): ReactElement => {
  return (
    <div title={item.title} data-testid="StorageLabel">
      <HighlightText className="option-text" text={item.title} criteria={searchParam} />
    </div>
  );
};
