import React, { ReactElement } from "react";
import { Assignment } from "auth-ui-client-api";
import styles from "./AssignmentSelectField.module.scss";
import classNames from "classnames";

export const render = (item: Assignment): ReactElement => {
  const assignment = [item.positionName, item.organizationName, item.departmentName].filter((it) => !!it);
  const title = assignment.join(", ");

  return (
    <div
      className={classNames(assignment.length > 1 && styles.multiline)}
      title={title}
      data-testid="UserAssignmentLabel"
    >
      <div className={styles.assignment}>
        <span className={styles.name}>{title || "-"}</span>
      </div>
    </div>
  );
};
