import { HighlightText } from "auth-ui-client";
import React from "react";
import styles from "./UserAccountLabel.module.scss";
import { UserAccountLabelProps } from "./UserAccountLabel.types";

export const UserAccountLabel: React.FC<UserAccountLabelProps> = ({ name, login, criteria }) => {
  const title = `${name} – ${login}`;

  return (
    <div className={styles.root} title={title} data-testid="UserAccountLabel">
      <HighlightText className={styles.name} text={name} criteria={criteria} />
      <HighlightText className={styles.login} text={login} criteria={criteria} />
    </div>
  );
};
