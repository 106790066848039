import React from "react";
import { IdentifierInputProps } from "./IdentifierInput.types";
import { MobileInput, inputIdForIdentifier, labelForIdentifier } from "auth-ui-client";
import { kigFieldNames } from "@/localizations/KigFieldNames";
import { FormItem } from "../FormItem";

export const MobileInputAdapter: React.FC<IdentifierInputProps> = ({
  identifierType,
  setIdentifier,
  identifier,
  showLabel,
  showPlaceholder,
  mobileValidation,
  setMobileValidation,
  className,
}) => (
  <FormItem
    validateStatus={mobileValidation?.validateStatus}
    help={mobileValidation?.help}
    label={showLabel ? labelForIdentifier(identifier, kigFieldNames) : undefined}
    htmlFor={inputIdForIdentifier(identifier)}
  >
    <MobileInput
      id={identifierType}
      identifier={identifier}
      setIdentifier={(val) =>
        setIdentifier((prevState) => {
          return {
            ...prevState,
            mobile: val,
            type: identifierType,
          };
        })
      }
      className={className}
      name="MOBILE"
      placeholder={showPlaceholder ? kigFieldNames.MOBILE_PLACEHOLDER : undefined}
      onChangeAction={() => setMobileValidation(undefined)}
    />
  </FormItem>
);
