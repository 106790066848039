import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Select, Tag } from "antd";

import { DefaultOptionType } from "rc-select/lib/Select";
import { CustomTagProps } from "rc-select/lib/BaseSelect";

import { CaretDownFilled, CaretUpFilled, CloseOutlined } from "@ant-design/icons";
import {
  SelectLabelProps,
  SelectSingleValue,
  isCanceled,
  isError,
} from "auth-ui-client/src/components/select/properties";
import { getDomainObject, getValue } from "auth-ui-client/src/components/select/convertionUtils";
import { clientLogger } from "auth-ui-client";
import StatusBar from "auth-ui-client/src/components/select/StatusBar";
import { defaultItemsToOptions } from "./SelectNext.utils";
import { NOT_FOUND_CONTENT } from "./SelectNext.const";
import { SelectNextProps } from "./SelectNext.types";
import styles from "./SelectNext.module.scss";

export function SelectNext<P>(props: SelectNextProps<P>): ReactElement {
  const {
    getId,
    render,
    getItems,
    defaultValue,
    value,
    disabledIds = [],
    minInputForSearch = 0,
    maxInputForSearch = 256,
    showSearch = true,
    itemsToOptions = defaultItemsToOptions,
    popupContainerTriggerNode = true,
    ...propagationProps
  } = props;
  const { id } = propagationProps;

  const [internalValue, setInternalValue] = useState<SelectSingleValue<P> | undefined>(
    getValue<P>(defaultValue, render, getId, disabledIds)
  );

  const controlled = "value" in props;
  useEffect(() => {
    if (controlled) {
      setInternalValue(getValue<P>(value, render, getId, disabledIds));
    }
    // Чтобы не оборачивать все в useMemo/callback
    // eslint-disable-next-line
  }, [value]);

  const [searchCriteria, setSearchCriteria] = useState<string>("");
  const [items, setItems] = useState<P[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const offsetRef = useRef(0);

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [notFoundContent, setNotFoundContent] = useState<React.ReactNode>(
    minInputForSearch > 0 ? <></> : NOT_FOUND_CONTENT
  );

  const [isActive, setActive] = useState(true);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const onChangeHandler = (newValue?: SelectSingleValue<P>, option?: DefaultOptionType | DefaultOptionType[]) => {
    if (!controlled) {
      setInternalValue(newValue);
    }
    const domainObject = getDomainObject<P>(newValue);
    clientLogger.debug(
      `#${id}: значения внутри`,
      newValue,
      `значение снаружи `,
      domainObject,
      `выбраны options `,
      option
    );
    if (props.onChange) {
      props.onChange(domainObject);
    }
  };

  /**
   * Подгружаем элементы при первом открытии
   * @param open
   */
  const onDropdownVisibleChange = async (open: boolean) => {
    setOpened(open);
    const closing = !open;
    const hasInputSearchMinimum = minInputForSearch > 0;

    if (closing && searchCriteria.length) {
      // см. аналогичное поведение у tree select
      setSearchCriteria("");
      return;
    }

    if (closing || hasInputSearchMinimum) {
      return;
    }
    offsetRef.current = 0;
    await loadItems(0, "", true);
  };

  const onSearch = async (currentSearch: string): Promise<void> => {
    setSearchCriteria(currentSearch.slice(0, maxInputForSearch + 1));
    setHasMore(false);
    setError(false);
    const searchValueLength = currentSearch ? currentSearch.length : 0;
    if (searchValueLength < minInputForSearch || searchValueLength > maxInputForSearch) {
      setNotFoundContent(<></>);
      setHasMore(false);
      setItems([]);
      return;
    }
    setNotFoundContent(NOT_FOUND_CONTENT);
    offsetRef.current = 0;
    await loadItems(0, currentSearch, true);
  };

  const onPopupScroll = async (e: { persist: () => void; target: any }) => {
    e.persist();
    const target = e.target;

    // При изменении масштаба браузера значение target.scrollTop может принимать дробные значения, чуть меньшие, чем при 100%
    // Напр., не 740 а 739,3 - округление до следующего целого решает эту проблему
    // +1 также для масштаба 67%
    const scrollToBottom = Math.ceil(target.scrollTop) + 1 + target.offsetHeight >= target.scrollHeight;
    /*    logger.debug(
      "target.scrollTop",
      target.scrollTop,
      Math.ceil(target.scrollTop) + target.offsetHeight,
      target.scrollHeight
    ); */
    if (!hasMore || !scrollToBottom) {
      return;
    }
    const newPageOffset = items.length;

    if (newPageOffset === offsetRef.current) {
      // при достижении конца списка метод onPopupScroll вызывается дважды,
      // поэтому мы запоминаем значение offset для которого сейчас уже выполняется запрос,
      // и не оправляем запрос повторно для этого же offset
      return;
    }
    offsetRef.current = newPageOffset;
    await loadItems(newPageOffset, searchCriteria, false);
  };

  const loadItems = async (offset: number, currentSearch: string, resetPreviousItems: boolean) => {
    setLoading(true);
    setError(false);
    try {
      const data = await getItems(offset, currentSearch);
      if (isCanceled(data)) {
        return;
      }
      if (isError(data)) {
        setError(true);
        return;
      }
      setHasMore(data.hasMore);
      resetPreviousItems ? setItems(data.items) : setItems((prevItems) => [...prevItems, ...data.items]);
      clientLogger.debug(`#${id}: получены данные`, data);
    } catch (err) {
      clientLogger.error(`#${id}: ошибка при получении данных`, err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getPopupContainer = (triggerNode: HTMLElement): HTMLElement => triggerNode;

  const dropdownRender = (menu: ReactElement): ReactElement => {
    return (
      <>
        {menu}
        <StatusBar
          loading={loading}
          error={error}
          minInputForSearch={minInputForSearch}
          maxInputForSearch={maxInputForSearch}
          searchValueLength={searchCriteria?.length}
        />
      </>
    );
  };

  const tagRender = (props: CustomTagProps): React.ReactElement => {
    const { label, ...ownProps } = props;
    const selectLabel = label as ReactElement<SelectLabelProps<P>>;
    return <Tag {...ownProps}>{render(selectLabel.props.domainObject)}</Tag>;
  };

  return (
    <Select<SelectSingleValue<P>>
      {...propagationProps}
      open={opened}
      value={internalValue}
      showSearch={showSearch}
      labelInValue
      optionLabelProp="label" // иначе при выборе элемента в логах появляется warning  `label` of `value` is not same as `label` in Select options.
      searchValue={searchCriteria}
      loading={loading}
      filterOption={false}
      style={{ width: "100%" }}
      className={styles.root}
      dropdownClassName={styles.dropdown}
      onChange={onChangeHandler}
      getPopupContainer={popupContainerTriggerNode ? getPopupContainer : undefined}
      dropdownRender={dropdownRender}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onSearch={showSearch ? onSearch : undefined}
      onPopupScroll={onPopupScroll}
      options={itemsToOptions({ items, render, getId, searchCriteria, disabledIds })}
      tagRender={tagRender}
      notFoundContent={loading || error || minInputForSearch > 0 ? " " : notFoundContent}
      // фикс баги анта с кастомной suffixIcon, по клику на которую не открывается дропдаун селекта
      // https://github.com/ant-design/ant-design/issues/23263#issuecomment-1079985930
      suffixIcon={
        opened ? (
          <CaretUpFilled className={isActive ? "ant-select-suffix" : ""} onClick={toggleClass} />
        ) : (
          <CaretDownFilled className={isActive ? "ant-select-suffix" : ""} onClick={toggleClass} />
        )
      }
      clearIcon={<CloseOutlined />}
      data-testid="SelectNext"
      dropdownAlign={{ offset: [0, -5] }}
    />
  );
}
