import React from "react";
import { IdentifierInputProps } from "./IdentifierInput.types";
import { INPUT_DICTIONARY } from "./IdentifierInput.const";

export const IdentifierInput: React.FC<IdentifierInputProps> = (props) => {
  const { identifierType } = props;

  const InputControl = INPUT_DICTIONARY[identifierType];

  return <InputControl {...props} />;
};
