import React from "react";
import classNames from "classnames";
import { captchaApiRequest, errorMessage, setCaptchaImage, setCaptchaValue, useCaptchaContext } from "auth-ui-client";

import { isCaptchaCheckApiSuccess } from "auth-ui-client-api";
import { CaptchaPanelProps } from "./CaptchaPanel.types";
import { kigFieldNames } from "@/localizations/KigFieldNames";
import Image from "next/image";
import styles from "./CaptchaPanel.module.scss";
import { Input } from "../Input";
import { FormItem } from "../FormItem";
import { ButtonLink } from "../ButtonLink";

const { CAPTCHA_LABEL, CAPTCHA_CODE_UPDATE } = kigFieldNames;

export const CaptchaPanel: React.FC<CaptchaPanelProps> = ({
  setErrors,
  placeholder,
  captchaImageError,
  captchaValidation,
  form,
  className,
}) => {
  const [captcha, dispatchCaptcha] = useCaptchaContext();

  const updateCaptcha = async (): Promise<void> => {
    dispatchCaptcha(setCaptchaValue(""));

    form.resetFields(["captcha"]);

    const captchaResponse = await captchaApiRequest(captcha.captchaId || "");

    if (isCaptchaCheckApiSuccess(captchaResponse)) {
      dispatchCaptcha(setCaptchaImage(captchaResponse.captchaImageBase64));

      return;
    }
    setErrors([errorMessage(captchaImageError)]);
  };

  if (!captcha.captchaImage) return null;

  return (
    <div className={classNames("mb-3", className)}>
      <div className="flex w-full justify-between items-center pb-3">
        <span className={styles.title}>{CAPTCHA_LABEL}</span>

        <ButtonLink onClick={updateCaptcha}>{CAPTCHA_CODE_UPDATE}</ButtonLink>
      </div>

      <div className="relative w-full rounded overflow-hidden h-10 mb-3">
        <Image alt="captcha" src={`data:image/png;base64, ${captcha.captchaImage}`} layout="fill" />
      </div>

      <FormItem
        validateStatus={captchaValidation?.validateStatus}
        help={captchaValidation?.help}
        className="!mb-0"
        shouldUpdate
      >
        <Input
          name="captcha"
          placeholder={placeholder}
          value={captcha.captchaValue || ""}
          maxLength={50}
          onChange={(event) => {
            dispatchCaptcha(setCaptchaValue(event.target.value));
          }}
          data-testid="CaptchaInput"
        />
      </FormItem>
    </div>
  );
};
