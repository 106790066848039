import { GetStringProp, RenderFunction, SelectSingleValue } from "auth-ui-client/src/components/select/properties";
import { toSelectSingleValue } from "auth-ui-client/src/components/select/convertionUtils";

export const defaultItemsToOptions = <DomainObject>({
  items,
  render,
  getId,
  searchCriteria,
  disabledIds,
}: {
  items: DomainObject[];
  render: RenderFunction<DomainObject>;
  getId: GetStringProp<DomainObject>;
  searchCriteria: string;
  disabledIds?: string[];
}): SelectSingleValue<DomainObject>[] => {
  return items.map((it: DomainObject) => {
    return toSelectSingleValue<DomainObject>(it, render, getId, searchCriteria, disabledIds);
  });
};
